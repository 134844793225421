<template>
    <div class="main-filter">
        <!-- 搜索框 -->
        <div class="main-filter-search">
            <search-component>
            </search-component>
        </div>
        <!-- 筛选条件 -->
        <div class="main-filter-group">
            <filter-list
                :filter-array="testArray1">
            </filter-list>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                testArray1: [
                    {
                        value: 1,
                        label: "彩妆套装"
                    },
                    {
                        value: 2,
                        label: "口红粉底液/膏"
                    },
                    {
                        value: 3,
                        label: "气垫BB/BB霜"
                    },
                    {
                        value: 4,
                        label: "气垫CC/CC霜"
                    },
                    {
                        value: 5,
                        label: "蜜粉/散粉"
                    },
                    {
                        value: 6,
                        label: "遮瑕"
                    },
                    {
                        value: 7,
                        label: "粉饼"
                    },
                    {
                        value: 8,
                        label: "隔离霜/妆前乳"
                    },
                    {
                        value: 9,
                        label: "唇彩唇蜜/唇釉"
                    },
                    {
                        value: 10,
                        label: "男士彩妆"
                    },
                    {
                        value: 11,
                        label: "其它彩妆"
                    },
                    {
                        value: 12,
                        label: "香水"
                    },
                    {
                        value: 13,
                        label: "眉笔/眉粉"
                    },
                    {
                        value: 14,
                        label: "美甲产品"
                    },
                    {
                        value: 15,
                        label: "眼影"
                    },
                    {
                        value: 16,
                        label: "眼线笔/眼线液"
                    },
                    {
                        value: 17,
                        label: "睫毛膏/增长液"
                    },
                    {
                        value: 18,
                        label: "腮红/胭脂"
                    },
                    {
                        value: 19,
                        label: "唇笔/唇彩笔"
                    }
                ],
            }
        },
        components: {
            searchComponent: () => import("@/components/imageMall/searchComponent"),
            filterList: () => import("@/components/cosmetics/filterList")
        },
    }
</script>

<style scoped>
.main-filter-search{
    width: 60%;
    margin: 0 auto;
}
.main-filter-group{
    margin-top: 50px;
}
.main-filter-group > div + div{
    margin-top: 10px;
}
</style>